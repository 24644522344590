import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import useSharesPage from '../../utils/use-shares-page'
import cx from 'classnames'
import { useMediaQuery } from 'react-responsive'

export default function MajorShareholders(props: PageProps) {
  const sharesPage = useSharesPage()
  const majorShareholdersPage = props.data.majorShareholdersPage
  const majorShareholders = props.data.majorShareholders.edges
  const annotations = []
  
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  majorShareholders.forEach((result) => {
    if (result.node.annotationRef) {
      result.node.annotationRef.forEach((annotation) => {
        if (!annotations.includes(annotation.text.text)) {
          annotations.push(annotation.text.text)
        }
      })
    }

    if (result.node.annotationRefVotingRights) {
      result.node.annotationRefVotingRights.forEach((annotation) => {
        if (!annotations.includes(annotation.text.text)) {
          annotations.push(annotation.text.text)
        }
      })
    }
  })


  return (
    <Layout
      location={props.location}
      tabs="shareInfo"
      heros={majorShareholdersPage.hero}
      herosFallbackTitle={sharesPage.title}
      herosFallbackSecondaryTitle={majorShareholdersPage.title}
      showStock
    >
      <div className="container flex flex-col mx-auto mt-4 lg:mt-0 mb-24">
        <div className="relative block my-4 text-xs tracking-wide uppercase font-univers">
          Major Shareholders
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {majorShareholdersPage.body.childMarkdownRemark && (
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 text-sm prose dark:prose-dark mb-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: majorShareholdersPage.body.childMarkdownRemark.html
                }}
                className="mx-auto"
              />
            </div>
          )}
        </div>

        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4"></div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isMobile ? (
              <div>
                {majorShareholders.map((result) => {
                  return (
                    <div
                      key={result.node.date}
                      className="w-full my-3 px-6 py-3 bg-gray-100 dark:bg-gray-900"
                    >
                      <div className="flex py-2">
                        <span className="w-28 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Shareholder
                        </span>
                        <div className="text-sm leading-tight text-gray-900 dark:text-gray-200">
                          {result.node.title}
                        </div>
                      </div>
                      <div className="flex py-2">
                        <span className="w-28 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Notification Date
                        </span>
                        <div className="text-sm leading-tight text-gray-900 dark:text-gray-200">
                          {result.node.date}
                        </div>
                      </div>
                      <div className="flex py-2">
                        <span className="w-28 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Capital %
                        </span>
                        <div className="text-sm leading-tight text-gray-900 dark:text-gray-200">
                          {result.node.totalRegistered.toFixed(2) + '%'}
                          {result.node.annotationRef && (
                            <sup>
                              {result.node.annotationRef.map((annotation, i) => (
                                <>
                                  {annotations.indexOf(annotation.text.text) + 1}
                                  {(result.node.annotationRef.length > (i+1)) && (
                                    <>,</>
                                  )}
                                </>
                              ))}
                            </sup>
                          )}
                        </div>
                      </div>
                      <div className="flex py-2">
                        <span className="w-28 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Voting Rights %
                        </span>
                        <div className="text-sm leading-tight text-gray-900 dark:text-gray-200">
                          {result.node.votingRights.toFixed(2) + '%'}
                          {result.node.annotationRefVotingRights && (
                            <sup>
                              {result.node.annotationRefVotingRights.map((annotation, i) => (
                                <>
                                  {annotations.indexOf(annotation.text.text) + 1}
                                  {(result.node.annotationRefVotingRights.length > (i+1)) && (
                                    <>,</>
                                  )}
                                </>
                              ))}
                            </sup>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <table className="min-w-full overflow-hidden divide-y divide-gray-200 shadow dark:divide-gray-700 sm:rounded-lg">
                <thead className="bg-gray-100 dark:bg-gray-800">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Shareholder
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Notification Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                    >
                      Capital %
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                    >
                      Voting Rights %
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {majorShareholders.map((result) => {

                    const titleClassNames = cx('px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap', {
                      'italic': result.node.italics === true
                    })

                    return (
                      <tr key={result.node.date}>
                        <td className={titleClassNames}>
                          {result.node.title}

                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-900 capitalize dark:text-gray-200 whitespace-nowrap">
                          {result.node.date}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 capitalize dark:text-gray-200 whitespace-nowrap">
                          {result.node.totalRegistered.toFixed(2) + '%'}
                          {result.node.annotationRef && (
                            <sup>
                              {result.node.annotationRef.map((annotation, i) => (
                                <>
                                  {annotations.indexOf(annotation.text.text) + 1}
                                  {(result.node.annotationRef.length > (i+1)) && (
                                    <>,</>
                                  )}
                                </>
                              ))}
                            </sup>
                          )}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 capitalize dark:text-gray-200 whitespace-nowrap">
                          {result.node.votingRights.toFixed(2) + '%'}
                          {result.node.annotationRefVotingRights && (
                            <sup>
                              {result.node.annotationRefVotingRights.map((annotation, i) => (
                                <>
                                  {annotations.indexOf(annotation.text.text) + 1}
                                  {(result.node.annotationRefVotingRights.length > (i+1)) && (
                                    <>,</>
                                  )}
                                </>
                              ))}
                            </sup>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="inline-block min-w-full py-6 align-middle text-sm prose dark:prose-dark">
          <div
            dangerouslySetInnerHTML={{
              __html: majorShareholdersPage.disclaimerText.childMarkdownRemark.html
            }}
          />
        </div>

        {annotations.length > 0 && (
          <div className="inline-block min-w-full py-6 align-middle text-sm prose dark:prose-dark">
            {annotations.map((result, index) => (
              <div key={index}>
                <p>
                  <sup>{index + 1}</sup>{' ' + result}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 text-sm dark:prose-dark mb-10">
            {majorShareholdersPage.summary && (
              <div
                dangerouslySetInnerHTML={{
                  __html: majorShareholdersPage.summary.childMarkdownRemark.html
                }}
                className="mx-auto mt-6"
              />
            )}
            {majorShareholdersPage.noResults && (
              <div
                dangerouslySetInnerHTML={{
                  __html: majorShareholdersPage.noResults.childMarkdownRemark.html
                }}
                className="mx-auto mt-6 whitespace-pre-line"
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    majorShareholdersPage: contentfulCustomPage(
      slug: { eq: "major-shareholders" }
    ) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      noResults {
        childMarkdownRemark {
          html
        }
      }
      disclaimerText{
        childMarkdownRemark {
          html
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      hero {
        ...HeroFragment
      }
    }
    majorShareholders: allContentfulMajorShareholder(
      sort: {order: [DESC, ASC, DESC, ASC], fields: [sticky, weight, date, title]}
    ) {
      edges {
        node {
          title
          date
          totalRegistered
          votingRights
          italics
          annotationRef {
            contentful_id
            text {
              text
            }
            id
          }
          annotationRefVotingRights {
            contentful_id
            text {
              text
            }
            id
          }
        }
      }
    }
  }
`
